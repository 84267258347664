<template>
  <td
    :colspan="colspan ? colspan : 20"
    class="TicketsDetailsTransactions pb-2"
  >
    <v-simple-table
      v-for="placeData in placeDetails"
      :key="'transaction-details-place-' + transactionId + '-' + placeData.sitio"
    >
      <thead>
        <tr>
          <td class="place-title">
            {{ placeData.shortname }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr style="background-color: rgba(255, 165, 0, 0.4);">
          <td>
            <v-simple-table>
              <thead>
                <tr
                  style="background: rgb(253 142 48 / 22%); color: black"
                >
                  <td align="center">
                    <strong>Tipo de boleto</strong>
                  </td>
                  <td :style="`width:${detailed ? 30 : 40}%;`">
                    <strong>Nombre del Boleto</strong>
                  </td>
                  <td align="center">
                    <strong>Cantidad</strong>
                  </td>
                  <td
                    v-if="detailed"
                    align="center"
                  >
                    <strong>Impreso(s)</strong>
                  </td>
                  <td
                    v-if="detailed"
                    align="Disponibles(s)"
                  >
                    <strong>Disponibles(s)</strong>
                  </td>
                  <td align="right">
                    <strong>Precio Unitario</strong>
                  </td>
                  <td align="right">
                    <strong>Costo</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="ticketData in placeData.transaccion"
                  :key="`transaction-details-place-${transactionId}-${placeData.sitio}-${ticketData.id_ticket}-${ticketData.persontype}-${ticketData.npool}`"
                >
                  <td align="center">
                    {{ ticketData.id_ticket }}
                  </td>
                  <td>
                    {{ ticketData.nombre }}
                    <span v-if="ticketData.alias">({{ ticketData.alias }})</span>
                  </td>
                  <td align="center">
                    {{ ticketData.cantidad }}
                  </td>
                  <td
                    v-if="detailed"
                    align="center"
                  >
                    {{ $formatNum(ticketData.usado) }}
                  </td>
                  <td
                    v-if="detailed"
                    align="center"
                  >
                    {{ $formatNum(ticketData.n_usado) }}
                  </td>
                  <td
                    align="right"
                    class="font-weight-bold"
                  >
                    ${{ $formatPrice(ticketData.costo) }} <ExchangeCurrency />
                  </td>
                  <td
                    align="right"
                    class="font-weight-bold"
                  >
                    ${{ $formatPrice(ticketData.total) }} <ExchangeCurrency />
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="placeData.total">
                <tr style="background: rgb(253 142 48 / 22%); color: black">
                  <td
                    :colspan="detailed ? 6 : 4"
                    align="right"
                  >
                    <strong>Total: </strong>
                  </td>
                  <td
                    align="right"
                    class="place-total pt-0"
                  >
                    <strong>${{ $formatPrice(placeData.total) }} <ExchangeCurrency /></strong>
                  </td>
                </tr>
              </tfoot>
            </v-simple-table>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </td>
</template>

<script>
export default {
  name: 'TicketsDetailsTransactions',
  props: ['colspan', 'placeDetails', 'transactionId', 'detailed']
}
</script>

<style scoped>
  .place-title {
    background: white;
    padding: 15px 0px 0px 5px !important;
    font-weight: bold;
  }
</style>

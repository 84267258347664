<template>
  <v-card
    class="user-noselect pa-3"
    :max-width="maxWidth"
  >
    <div class="container-images">
      <img
        src="../../assets/logoticket2pass.png"
        class="image-logo-t2p"
        alt="logo-t2p"
      >

      <img
        src="../../assets/ticket2pass.png"
        class="image-name-t2p"
        alt="name-image-t2p"
      >

      <div
        v-if="appVersion"
        class="container-version-app"
      >
        versión: {{ appVersion }}
      </div>
    </div>

    <div class="item-login">
      <v-form ref="formLogin">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="userEmail"
              append-icon="mdi-account"
              name="Usuario"
              label="Usuario"
              type="text"
              outlined
              :rules="[$rules.required]"
              hide-details="auto"
              @keyup.13="login"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              id="password"
              v-model="password"
              :type="hidePassword ? 'password' : 'text'"
              :append-icon="`mdi-${hidePassword ? 'eye-off' : 'eye'}`"
              name="password"
              label="Password"
              outlined
              :rules="[$rules.required]"
              hide-details="auto"
              @click:append="hidePassword = !hidePassword"
              @keyup.13="login"
            />
          </v-col>
        </v-row>
        <v-row
          justify="center"
          style="gap: 30px"
        >
          <v-btn
            :color="'#dd8159'"
            min-width="148"
            class="my_font"
            @click="changeView"
          >
            Crear cuenta
          </v-btn>

          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            class="my_font"
            min-width="148"
            @click="login"
          >
            Entrar
          </v-btn>
        </v-row>
        <a
          class="recoveryPassword"
          @click="changeRecoveryPass"
        >
          Recupera tu contraseña
        </a>
      </v-form>
    </div>
    <div class="item-recopass">
      <v-form ref="formRecovery">
        <h5 class="py-2">
          Escribe el correo registrado para recuperar contraseña.
        </h5>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="userEmail"
              append-icon="mdi-email"
              name="Usuario"
              label="Correo electronico"
              type="text"
              outlined
              :rules="[$rules.required, $rules.isEmail]"
              hide-details="auto"
              @keyup.13="recoveryPassword"
            />
          </v-col>
        </v-row>
        <v-row
          justify="center"
          style="gap: 30px"
        >
          <v-btn
            :color="'#dd8159'"
            min-width="124"
            class="my_font"
            @click="changeRecoveryPass"
          >
            Regresar
          </v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            class="my_font"
            @click="recoveryPassword"
          >
            Recuperar
          </v-btn>
        </v-row>
      </v-form>
    </div>

    <TwoFactorAuth
      v-model="showTwoFactorAuth"
      message="iniciar sesión"
      text-button="Iniciar sesión"
      :user="userToken"
      @success="handleSuccessTwoFactorAuth"
    />
  </v-card>
</template>

<script>
import $ from 'jquery'
import { version } from '../../../package.json'

// Vue
import { mapMutations } from 'vuex'
import TwoFactorAuth from '../tools/TwoFactorAuth.vue'

export default {
  name: 'LoginCard',
  components: { TwoFactorAuth },
  props: {
    maxWidth: {
      required: false,
      default: '350px'
    }
  },
  data () {
    return {
      appVersion: version,
      userEmail: '',
      password: '',
      hidePassword: true,
      loading: false,
      recoPass: false,
      animationsReverse: false,
      showTwoFactorAuth: false,
      userToken: {}
    }
  },
  watch: {
    recoPass: function () {
      this.userEmail = ''
      this.password = ''
      this.$refs.formLoginAndRecovery.resetValidation()
    }
  },
  methods: {
    ...mapMutations({
      loginUser: 'auth/loginUser',
      setLoadingMainApp: 'globalStates/setLoadingMainApp'
    }),
    login: function () {
      if (!this.$refs.formLogin.validate()) return

      this.loading = true
      this.setLoadingMainApp(true)
      const data = {
        user: this.userEmail,
        password: this.password
      }
      this.$root.post('/auth/', data)
        .then((response) => {
          const data = response?.data
          const dataStore = data?.[0]

          if (data?.success === 'FALSE' || dataStore?.success === 'FALSE') return this.$swalAlert('error', 'Acceso denegado', data.message || dataStore?.message)

          const userToken = data.id ? data : dataStore
          this.handleUserToken(userToken)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingMainApp(false)
        })
    },
    handleSuccessTwoFactorAuth: function () {
      const userToken = this.userToken
      this.userToken = {}
      this.loginUser(userToken)
      this.$router.push({ name: 'Mayoristas' })
      this.$root.$Toast('success', `Bienvenido ${userToken.name}`)
    },
    handleUserToken: function (userToken) {
      const routesName = {
        4: 'Mayoristas',
        7: 'Reportes',
        8: 'Reportes'
      }
      const routerName = routesName?.[userToken.rol] || 'Dashboard'

      if (userToken.rol === 4 && userToken.companyState === 3) {
        if (userToken.subrol !== 0) {
          const title = 'Empresa suspendida'
          const message = `Lamentamos informarte que tu cuenta se encuentra temporalmente suspendida porque la cuenta de tu empresa aún no ha sido validada. 
          Por favor, ponte en contacto con tu administrador para completar el proceso de verificación. 
          ¡Gracias por tu comprensión y paciencia!.`
          this.$swalAlert('error', title, message)
          return
        }
      }

      if (userToken.rol === 4 && userToken.hasTwoFactorAuth) {
        this.userToken = userToken
        this.showTwoFactorAuth = true
        return
      }

      this.loginUser(userToken)
      this.$router.push({ name: routerName })
      this.$root.$Toast('success', `Bienvenido ${userToken.name}`)
    },
    changeView: function () {
      this.$emit('changeView')
    },
    changeRecoveryPass: function () {
      if (!this.animationsReverse) {
        $('.item-login').slideToggle('slow')
        setTimeout(() => {
          $('.item-recopass').slideToggle('slow')
        }, 700)
      } else {
        $('.item-recopass').slideToggle('slow')
        setTimeout(() => {
          $('.item-login').slideToggle('slow')
        }, 500)
      }
      this.animationsReverse = !this.animationsReverse
      setTimeout(() => {
        this.userEmail = ''
        this.password = ''
        this.$refs.formLogin.reset()
        this.$refs.formLogin.resetValidation()
        this.$refs.formRecovery.reset()
        this.$refs.formRecovery.resetValidation()
      }, 500)
    },
    recoveryPassword: function () {
      if (!this.$refs.formRecovery.validate()) return
      this.loading = true
      this.setLoadingMainApp(true)

      this.$root.post('/auth/recoveryPassword', {
        email: this.userEmail
      }).then((response) => {
        const data = response.data
        if (data.success === 'FALSE') {
          this.$swalAlert('error', 'Error', data.message)
          return
        }

        this.$swalAlert('success', 'Success', data.message)
        this.recoPass = false
      })
        .finally(() => {
          this.loading = false
          this.setLoadingMainApp(false)
        })
    }
  }
}
</script>

<style scoped>
.container-images {
  overflow: hidden;
  padding-bottom: 5px;
}

.image-logo-t2p {
  max-height: 200px;
  max-width: auto;
  display: block;
  margin-inline: auto;
  filter: drop-shadow(0 0 0.55rem #4e54c8);
}

.image-name-t2p {
  max-width: 100%;
  max-height: auto;
  display: block;
  margin-inline: auto;
  padding: 10px 20px 5px 20px;
  filter: drop-shadow(0 0 0.55rem #4e54c8);
}

.container-version-app {
  padding: 0px 4px 0px 4px;
  border-radius: 16px;
  font-weight: bold;
  font-size: 11px;
  text-align: right;
}

.recoveryPassword {
  text-decoration: none;
  color: #f07f3a;
  font-weight: bold;
  text-align: center;
  display: block;
  margin-top: 12px;
}

.recoveryPassword:hover {
  text-decoration: underline #f07f3a;
}

.my_font {
  margin-top: 5px;
  margin-bottom: 10px;
  color: white !important;
}

.item-login {
  display: block;
}

.item-recopass {
  display: none;
  padding-bottom: 5px;
}
</style>

<template>
  <div id="TicketsGenerated">
    <v-alert
      v-if="!detTicketsGenerated.length"
      icon="mdi-folder-alert-outline"
      prominent
      text
      type="warning"
      align="left"
    >
      Aún no boletos generados
    </v-alert>
    <v-data-table
      v-else
      :headers="headers"
      :items="detTicketsGenerated"
      class="m-4 mt-2"
      :search="search"
      mobile-breakpoint="Infinity"
    >
      <template v-slot:body="{ items }">
        <tbody v-if="!items.length">
          <td colspan="20">
            <v-alert
              class="mb-0 text"
              align="center"
            >
              Sin datos para esta búsqueda
            </v-alert>
          </td>
        </tbody>
        <tbody v-else>
          <template v-for="(printTransaction, index) in items">
            <tr
              class="pointman"
              style="color: black"
              :style="{ 'background-color': $backgroundRowTable(index), 'border-top':'2px solid #75695f' }"
              @click="clickrow(printTransaction.id)"
            >
              <td style="max-width: 10px">
                <v-icon class="ma-2">
                  mdi-{{ printTransaction.id === expanded ? 'minus' : 'plus' }}
                </v-icon>
              </td>
              <td class="">
                <strong>{{ printTransaction.clave }}</strong>
              </td>
              <td align="center">
                <strong>{{ printTransaction.f_solicitud }}</strong>
              </td>
              <td align="center">
                <strong>{{ printTransaction.cantidad }}</strong>
              </td>
              <td align="center">
                <strong>
                  {{ `${printTransaction.name} ${printTransaction.name2} ${printTransaction.name3}` }}
                </strong>
              </td>
              <td>
                {{ printTransaction.descript }}
              </td>
            </tr>

            <tr
              v-if="printTransaction.id === expanded"
              :key="`printTransaction-item-details-${printTransaction.id}`"
              style="background-color: white !important"
            >
              <td colspan="7">
                <v-simple-table
                  v-for="placeDetails in printTransaction.sitios"
                  :key="`printTransaction-item-details-${printTransaction.id}-${placeDetails.idSitio}`"
                >
                  <thead>
                    <tr>
                      <td class="place-title">
                        {{ placeDetails.nameSitio }}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="background-color: rgba(255, 165, 0, 0.4);">
                      <td>
                        <v-simple-table>
                          <thead>
                            <tr
                              style="background: rgb(253 142 48 / 22%); color: black"
                            >
                              <td align="center">
                                <strong>Tipo de boleto</strong>
                              </td>
                              <td align="center">
                                <strong>Boleto</strong>
                              </td>
                              <td align="center">
                                <strong>Cantidad</strong>
                              </td>
                              <td
                                v-if="canEdit"
                                align="center"
                              >
                                <strong>Imprimir</strong>
                              </td>
                              <td align="center">
                                <strong>Conteo de impresiones</strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="ticketData in placeDetails.data"
                              :key="`ticket-details-${printTransaction.id}-${placeDetails.idSitio}-${ticketData.id_ticket}-${ticketData.persontype}-${ticketData.npool}`"
                            >
                              <td align="center">
                                <strong>{{ ticketData.id_ticket }}</strong>
                              </td>
                              <td width="35%">
                                <strong>{{ ticketData.descripcion }} </strong>
                                <span v-if="ticketData.alias"> ({{ ticketData.alias }})</span>
                              </td>
                              <td align="center">
                                <strong>{{ ticketData.cantidad }}</strong>
                              </td>
                              <td
                                v-if="canEdit"
                                align="center"
                                width="40%"
                              >
                                <v-btn
                                  color="primary"
                                  @click="print(ticketData.id_ticket, printTransaction.id, printTransaction.clave, ticketData.npool, ticketData.persontype, ticketData.NTARIFBLATTNR, ticketData.NTARIFBLATTGUELTNR )"
                                >
                                  <i
                                    class="bx bxs-file m-2"
                                    style="font-size: 20px;"
                                  />pdf
                                </v-btn>
                              </td>
                              <td
                                class=" pt-2"
                                align="center"
                              >
                                <v-badge
                                  :content="ticketData.printed"
                                  :value="ticketData.printed"
                                  color="orange"
                                  overlap
                                >
                                  <v-icon
                                    v-if="ticketData.printed"
                                    style="font-size: 30px"
                                  >
                                    mdi-printer
                                  </v-icon>
                                </v-badge>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'

export default {
  name: 'TicketsGenerated',
  props: ['detTicketsGenerated', 'search'],
  data () {
    return {
      headers: [
        { text: '', value: 'icon' },
        { text: 'Clave de transaccion', align: 'start', value: 'clave' },
        { text: 'Fecha de solicitud', value: 'f_solicitud' },
        { text: 'Cantidad', value: 'cantidad', align: 'right' },
        { text: 'Nombre de Solitante', value: 'name', align: 'center' },
        { text: 'Descripcion', value: 'descript', align: 'center' }
      ],
      flag: false,
      expanded: ''
    }
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      userData: 'auth/userData'
    }),
    canEdit () {
      return this.userData?.subrol < 2
    }
  },
  watch: {
    search: function (val) {
      this.expanded = ''
    }
  },
  methods: {
    clickrow (id) {
      if (this.flag === false) {
        this.expanded = this.expanded === id ? '' : id
      } else {
        this.flag = false
      }
    },
    print: async function (ticketType, idTrans, clave, npool, persontype, NTARIFBLATTNR, NTARIFBLATTGUELTNR) {
      const self = this
      const imageUrl = 'https://gpstuuxan.com/img/ticket04.png'
      const inputOptions = {
        1: '1 Boleto',
        2: '2 Boletos',
        4: '4 Boletos'
      }
      this.$session.remove('tipo')
      const didOpen = () => {
        const input = self.$swal.getInput()
        const img = self.$swal.getImage()

        input.style.border = '3px solid darkslateblue'
        input.style.marginBottom = '0px'

        const arrowModified = $('<button type="button" style="font-size: 26px; font-weight: bold; background: transparent; color: darkslateblue; position: relative; top: -42px; left: 84px; padding-bottom: 0px; transform: rotate(90deg); ">&#x2799;</button>')

        setTimeout(() => {
          $('.swal2-select').after(arrowModified)
          $('.swal2-actions').css({ 'margin-top': '0' })
        }, 100)

        input.oninput = (value) => {
          const val = value.currentTarget.value
          img.src = `https://gpstuuxan.com/img/ticket0${val}.png`
        }
      }

      const result = await this.$swal({
        text: 'Seleccione la cantidad de boletos por hoja',
        imageUrl,
        imageWidth: 250,
        imageHeight: 200,
        input: 'select',
        inputValue: 4,
        inputOptions,
        didOpen,
        inputValidator: function (value) {
          return new Promise(function (resolve) {
            if (value === '') {
              return resolve('Seleccione una cantidad de boleto')
            }
            resolve()
          })
        }
      })

      if (!result.isConfirmed) return

      const tipoX = { ticketType, idTrans, npool, persontype, NTARIFBLATTNR, NTARIFBLATTGUELTNR, token: self.token, format: 2, amount: result.value }
      self.$session.set('tipo', tipoX)

      setTimeout(() => {
        const routeData = self.$router.resolve({ name: 'PdfGenerator' })
        window.open(routeData.href, '_blank')
      }, 10)
    }
  }
}
</script>

<style scoped>
.place-title {
  background: white;
  color: black;
  padding: 0 !important;
  font-size: 16px !important;
  height: 40px !important;
  font-weight: bold;
}
</style>

<style scoped src="../../assets/css/style-tables-dark.css">
</style>

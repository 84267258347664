<template>
  <v-dialog
    id="DialogPrintDetailsForm"
    v-model="dialog"
    max-width="700px"
    scrollable
  >
    <v-card tile>
      <v-toolbar
        dark
        style="border-radius: 0px !important"
      >
        <v-toolbar-title>
          Detalles de impresión
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="resetForm()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form
        ref="detailsPrintForm"
        @submit.prevent="print"
      >
        <v-text-field
          v-model="description"
          class="mx-5 mt-2 mb-1"
          label="Escriba una descripción de la transacción"
          :hint="`Máximo de ${maxCharacters} caracteres`"
          :counter="maxCharacters"
          :rules="[$rules.required, $rules.maxLength(description, maxCharacters)]"
          hide-details="auto"
          @keypress:enter="print"
        />
      </v-form>
      <v-card-text
        class="pt-1 py-0"
        max-height="calc(100vh - 75px)"
      >
        <v-simple-table
          v-for="(place, index) in ticketWithPlace"
          :key="`place-item-printe-${place.id}`"
          :class="index === 0 ? '' : 'mt-3'"
        >
          <thead>
            <tr>
              <td class="place-title">
                {{ place.nameSitio }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr style="background-color: rgba(255, 165, 0, 0.4)">
              <td>
                <v-simple-table>
                  <thead>
                    <tr
                      style="background: rgb(253 142 48 / 22%); color: black"
                    >
                      <td style="width: 70%">
                        <strong>Nombre del Boleto</strong>
                      </td>
                      <td
                        align="center"
                        style="width: 10%"
                      >
                        <strong>Cantidad</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item2, index2) in place.data"
                      :key="'seletedItemSitio-' + index2"
                    >
                      <td>{{ item2.name }}</td>
                      <td align="center">
                        {{ item2.cantidad }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <div class="text-center font-weight-bold my-1">
        ¿Está seguro de continuar con esta acción?
      </div>
      <v-divider class="pt-0" />

      <v-card-actions class="container-actions-buttons">
        <v-btn @click="resetForm()">
          Cancelar
        </v-btn>
        <v-btn
          class="primary"
          color="#df8157 !important"
          @click="print"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DialogPrintDetailsForm',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    ticketsForPrint: {
      type: Array,
      required: true,
      default () { return [] }
    }
  },
  data () {
    return {
      maxCharacters: 250,
      description: '',
      date: '',
      nowDate: new Date().toISOString().slice(0, 10)
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData',
      token: 'auth/token'
    }),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    ticketWithPlace: {
      get () {
        const sitiosID = []
        let ticketsSelected = []
        this.ticketsForPrint.forEach((element) => {
          if (!sitiosID.includes(element.sitio)) {
            sitiosID.push(element.sitio)
            const datos = []
            this.ticketsForPrint.forEach((item) => {
              if (element.sitio === item.sitio) datos.push(item)
            })

            datos.sort(function (a, b) {
              if (a.descripcion > b.descripcion) return 1
              if (a.descripcion < b.descripcion) return -1
              return 0
            })

            ticketsSelected.push({
              id: element.sitio,
              nameSitio: element.nameSitio,
              data: datos
            })
          }
        })
        ticketsSelected = ticketsSelected.sort(function (a, b) {
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1
          return 0
        })

        return ticketsSelected
      }
    }
  },
  mounted () {
    this.date = this.$sumarDias(new Date(), +1)
  },
  methods: {
    print: function () {
      if (!this.$refs.detailsPrintForm.validate()) {
        this.$Toast('warning', 'Campos invalidos')
        return
      }

      const { id, idCorp } = this.userData

      this.$root.socket.emit('printTickets', {
        user: id,
        idCorp,
        dateUse: this.date,
        isNowDate: this.date === this.nowDate ? 1 : 0,
        data: this.ticketsForPrint,
        token: this.token,
        descript: this.description
      })
      this.resetForm()
    },
    resetForm: function () {
      this.$refs.detailsPrintForm.reset()
      this.$refs.detailsPrintForm.resetValidation()
      this.description = ''
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.container-actions-buttons {
  justify-content: space-evenly;
}

.place-title {
  background: white;
  color: black;
  padding: 0 !important;
  font-size: 18px;
  height: 30px !important;
  font-weight: bold;
}
</style>

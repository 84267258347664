<!-- eslint-disable vue/no-v-html -->
<template>
  <div id="MainWholesalers">
    <NavBarWholesalers />

    <router-view class="container-catalogo-views" />

    <div
      v-if="showMessage"
      :class="`notifications ${openMessage ? 'opened' : ''} ${$route.fullPath !== '/home' ? 'not-home' : ''}`"
    >
      <v-alert
        v-model="openMessage"
        class="ma-0"
        :color="colorMessage"
        :icon="iconMessage"
        dark
        dismissible
      >
        {{ message }}
      </v-alert>

      <v-btn
        v-if="!openMessage"
        class="ma-1"
        small
        fab
        dark
        elevation="2"
        :color="colorMessage"
        @click="openMessage = true"
      >
        <v-icon>{{ iconMessage }}</v-icon>
      </v-btn>
    </div>
    <v-dialog
      v-if="showMessageMaintenance"
      v-model="openMessageMaintenance"
      max-width="650px"
      persistent
    >
      <v-card tile>
        <v-toolbar
          dark
          style="border-radius: 0px !important"
        >
          <v-card-title>
            <span class="text-h5">
              {{ titleMaintenance }}
            </span>
          </v-card-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="openMessageMaintenance = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          v-if="messageMaintenance"
          class="font-weight-bold text-subtitle-1 py-3 px-4"
        >
          <span v-html="messageMaintenance" />
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            @click="openMessageMaintenance = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Vue
import { mapGetters, mapMutations } from 'vuex'

// Mixins
import SOCKET from '../../minixs/socket'
import WHOLESALERS from '../../minixs/wholesalers'

// Components
import NavBarWholesalers from '../../components/wholesalers/NavBarWholesalers.vue'

export default {
  name: 'MainWholesalers',
  components: {
    NavBarWholesalers
  },
  mixins: [
    SOCKET,
    WHOLESALERS
  ],
  data () {
    return {
      showMessage: false,
      openMessage: false,
      message: '',
      colorMessage: 'primary',
      iconMessage: 'mdi-alert-circle-outline',
      showMessageMaintenance: false,
      openMessageMaintenance: true,
      titleMaintenance: '',
      messageMaintenance: ''
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData'
    })
  },
  created () {
    this.ejectSocket()
  },
  mounted () {
    if (this.userData?.rol !== 4) {
      if (this.$root.socket) this.$root.socket.disconnect()
      this.$router.push({ name: 'Login' })
      this.logoutUser()
      return
    }
    this.getTicketsAndSiteWhosaler()
    this.getMessage()
    this.getMessageMaintenance()
  },
  methods: {
    ...mapMutations({
      setLoadingMainApp: 'globalStates/setLoadingMainApp',
      setPlacesAvailable: 'wholesaler/setPlacesAvailable',
      setTicketsAvailable: 'wholesaler/setTicketsAvailable',
      logoutUser: 'auth/logoutUser'
    }),
    getTicketsAndSiteWhosaler: function () {
      this.setPlacesAvailable([])
      this.setTicketsAvailable([])
      this.setLoadingMainApp(true)

      this.$root.post('/wholesalers/getTicketsAndPlace')
        .then(
          (response) => {
            const data = response.data
            if (data.success === 'FALSE') {
              this.$swalAlert('error', 'Error', data.message)
              return
            }

            const placesAvailable = []
            const ticketsAvailable = []

            data.forEach(element => {
              const { id, sitio, name, img, nombre, alias, alias2, costo, npool, persontype, Fini, Ffin, NTARIFBLATTNR, NTARIFBLATTGUELTNR } = element
              const placeExist = placesAvailable.find((item) => item.id === sitio)

              if (!placeExist) placesAvailable.push({ id: sitio, name, img, cantidad: 0 })

              ticketsAvailable.push({
                id,
                sitio,
                name: nombre,
                alias,
                alias2,
                costo,
                npool,
                persontype,
                ini: Fini,
                fin: Ffin,
                cantidad: 0,
                firtsPress: true,
                NTARIFBLATTNR,
                NTARIFBLATTGUELTNR
              })
            })

            this.setPlacesAvailable(placesAvailable)
            this.setTicketsAvailable(ticketsAvailable)
          }
        ).finally(() => { this.setLoadingMainApp(false) })
    },
    getMessage: async function () {
      const { data } = await this.$root.post('/wholesalers/message')

      this.showMessage = !!data.show || false
      this.openMessage = !!data.open || false
      this.message = data.message || ''
      this.colorMessage = data.color || 'primary'
      this.iconMessage = data.icon || 'mdi-alert-circle-outline'
    },
    getMessageMaintenance: async function () {
      const { data } = await this.$root.post('/wholesalers/messageMaintenance')

      this.showMessageMaintenance = !!data.show
      this.openMessageMaintenance = !!data.open
      this.titleMaintenance = data.title || 'Mantenimiento'
      this.messageMaintenance = data.message || ''
    }
  }
}
</script>

<style scoped>
.notifications{
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index: 2;
  width: fit-content;
  transition: bottom 0.2s ease-in-out;
}

.notifications.not-home{
  bottom: 0;
}

.notifications.opened{
  width: 100%;
}

.container-catalogo-views {
  margin-top: var(--h-navbar) !important;
  max-height: calc(100vh - var(--h-navbar));
  overflow: auto;
}
</style>

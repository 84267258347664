import { render, staticRenderFns } from "./TitleReportsCard.vue?vue&type=template&id=94a43eda&scoped=true"
import script from "./TitleReportsCard.vue?vue&type=script&lang=js"
export * from "./TitleReportsCard.vue?vue&type=script&lang=js"
import style0 from "./TitleReportsCard.vue?vue&type=style&index=0&id=94a43eda&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94a43eda",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardText,VIcon,VRow,VSpacer,VToolbar,VToolbarTitle})

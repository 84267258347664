import { render, staticRenderFns } from "./TableCartTransaction.vue?vue&type=template&id=04d9ce10&scoped=true"
import script from "./TableCartTransaction.vue?vue&type=script&lang=js"
export * from "./TableCartTransaction.vue?vue&type=script&lang=js"
import style0 from "./TableCartTransaction.vue?vue&type=style&index=0&id=04d9ce10&prod&scoped=true&lang=css"
import style1 from "../../assets/css/style-tables-dark.css?vue&type=style&index=1&id=04d9ce10&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d9ce10",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VBtn,VCard,VDataTable,VIcon,VTooltip})

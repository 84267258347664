import { render, staticRenderFns } from "./CompaniesWholesalers.vue?vue&type=template&id=ad6d2da8&scoped=true"
import script from "./CompaniesWholesalers.vue?vue&type=script&lang=js"
export * from "./CompaniesWholesalers.vue?vue&type=script&lang=js"
import style0 from "./CompaniesWholesalers.vue?vue&type=style&index=0&id=ad6d2da8&prod&scoped=true&lang=css"
import style1 from "../../../assets/css/style-tables-dark.css?vue&type=style&index=1&id=ad6d2da8&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad6d2da8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VCard,VCardText,VCardTitle,VCheckbox,VChip,VDataTable,VDivider,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemTitle,VMenu,VSkeletonLoader,VSpacer,VSubheader,VTextField})

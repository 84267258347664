import { render, staticRenderFns } from "./ImpressionHistoryTicketsCorps.vue?vue&type=template&id=097dbdb8&scoped=true"
import script from "./ImpressionHistoryTicketsCorps.vue?vue&type=script&lang=js"
export * from "./ImpressionHistoryTicketsCorps.vue?vue&type=script&lang=js"
import style0 from "./ImpressionHistoryTicketsCorps.vue?vue&type=style&index=0&id=097dbdb8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "097dbdb8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardText,VIcon,VSimpleTable,VSkeletonLoader,VSlideYReverseTransition,VSpacer,VToolbar,VToolbarTitle})
